import React from 'react'

const PrivacyPolicy = () => {

    
  return (
    <div>PrivacyPolicy</div>
  )
}

export default PrivacyPolicy